<!-- Localized -->
<template>
<modal
  name="referee-company-modal"
  ref="referee-company-modal"
  width="30%"
  height="auto"
  class="rounded-full"
  v-bind="$attrs"
  v-on="$listeners"
>
  <div v-if="data" class="flex flex-col justify-between min-h-full h-full bg-card-bg">
    <header class="modal-header h-10 2xl:h-12 flex items-center justify-between px-4 py-2.5 border-b border-0 border-solid border-gray-200">
      <span class="font-semibold text-base 2xl:text-xl tracking-wide text-white-text flex items-center justify-center gap-3">
        <HeaderIcon class="modal-header__icon w-5 h-5 2xl:w-7 2xl:h-7" />
        <span>{{ $t('components.tenant.sc_journey.s_referee', [data.referee_name]) }}</span>
      </span>
      <!--Handle close svg-->
      <CloseIcon class="cursor-pointer w-3.5 h-3.5 2xl:w-6 2xl:h-6" @click="closeModal" />
    </header>
    <div class="container p-4 flex-grow w-full max-w-full overflow-y-auto scroll-bar">
      <!-- form ui -->
      <div class="call-form-wrapper w-full h-full flex flex-col gap-4">
        <Field
          :title="$t('fields.street')"
          :placeholder="$t('fields.street')"
          v-model="modalData.street"
          fieldClass="px-4 bg-gray-200"
        />
        <Field
          :title="$t('fields.city')"
          :placeholder="$t('fields.city')"
          v-model="modalData.city"
          fieldClass="px-4"
        />
        <Field
          :title="$t('fields.postcode')"
          :placeholder="$t('fields.postcode')"
          v-model="modalData.postcode"
          fieldClass="px-4"
        />
        <Field
          :title="$t('fields.country')"
          :placeholder="$t('fields.country')"
          v-model="modalData.country"
          fieldClass="px-4"
        />
        <ToggleSwitch
          v-model="modalData.acquired"
          :label="$t('components.tenant.sc_journey.has_the_company_been_acquired_or_ceased_trading')"
        />
      </div>
    </div>
  </div>
</modal>
</template>

<script>
import Field from "@/components/sc-journey/field-modal.vue";
import CloseIcon from "@/assets/icons/profile-view/close-icon.svg";
import HeaderIcon from "@/assets/icons/sc-journey/company.svg";
import ToggleSwitch from "@/components/sc-journey/toggle-switch.vue";

export default {
  name: 'referee-company-modal',
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    HeaderIcon,
    Field,
    CloseIcon,
    ToggleSwitch,
  },
  data() {
    return {
      modalData: {
        street: '',
        city: '',
        country: '',
        postcode: '',
        acquired: false,
      }
    }
  },
  mounted(){
      this.closeModal();
  },
  methods: {
    closeModal() {
      this.$modal.hide('referee-company-modal');
    },
    saveForm() {
        let modalData = this.modalData
        const qs = Object.keys(modalData).filter(key => (modalData[key] === '')).map((key) => `${key}`)
        if (qs.length > 0) {
          this.$toast.error(this.$t('general.mandatory_error'))
        } else {
          this.$emit('submit', this.modalData);
          this.closeModal();
          this.resetForm();
        }
    },
    resetForm(){
        this.modalData = {
          street: '',
          city: '',
          country: '',
          postcode: '',
          acquired: false,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  &__icon {
    fill: white;
  }
}
.input-container {
  @apply flex flex-col gap-2;
}
::v-deep {
  .formulate-input-element {
    input {
      @apply rounded-xl;
      background-color: #F2F2F2;
      border: none;
      color: #282828;
    }
  }
}
</style>