<!-- Localized -->
<template>
    <div v-show="isShowDetailCanvas">
      <div
        v-if="isShowDetailCanvas"
        class="overlay"
      ></div>
      <div :class="{ 'offcanvas-open': isShowDetailCanvas }" class="offcanvas">
        <div class="offcanvas-content h-full">
            <div class="flex justify-between p-4 pl-8">
               <div class="flex items-center">
                <div class="personIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M3.33301 18.3333C3.33301 14.6513 6.31777 11.6666 9.99967 11.6666C13.6816 11.6666 16.6663 14.6513 16.6663 18.3333H14.9997C14.9997 15.5718 12.7611 13.3333 9.99967 13.3333C7.23825 13.3333 4.99967 15.5718 4.99967 18.3333H3.33301ZM9.99967 10.8333C7.23717 10.8333 4.99967 8.59575 4.99967 5.83325C4.99967 3.07075 7.23717 0.833252 9.99967 0.833252C12.7622 0.833252 14.9997 3.07075 14.9997 5.83325C14.9997 8.59575 12.7622 10.8333 9.99967 10.8333ZM9.99967 9.16658C11.8413 9.16658 13.333 7.67492 13.333 5.83325C13.333 3.99159 11.8413 2.49992 9.99967 2.49992C8.15801 2.49992 6.66634 3.99159 6.66634 5.83325C6.66634 7.67492 8.15801 9.16658 9.99967 9.16658Z" fill="#0D68D7"/>
                </svg>
                </div>
                <span class="ml-3">{{ outputData?.[0]?.details?.full_name }}</span>
               </div>
                <svg @click="closeDetailCanvas" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M18.3056 11.5204L15.8281 14L18.3088 16.4796C18.5465 16.7173 18.6801 17.0398 18.6801 17.3761C18.6801 17.7123 18.5465 18.0348 18.3088 18.2725C18.071 18.5103 17.7485 18.6439 17.4123 18.6439C17.076 18.6439 16.7535 18.5103 16.5158 18.2725L14.0352 15.793L11.5556 18.2736C11.3178 18.5114 10.9954 18.6449 10.6591 18.6449C10.3229 18.6449 10.0004 18.5114 9.76262 18.2736C9.52486 18.0358 9.39129 17.7134 9.39129 17.3771C9.39129 17.0409 9.52486 16.7184 9.76262 16.4806L12.2422 14L9.76473 11.5204C9.647 11.4027 9.55362 11.2629 9.4899 11.1091C9.42619 10.9553 9.3934 10.7904 9.3934 10.6239C9.3934 10.2877 9.52697 9.96522 9.76473 9.72746C10.0025 9.4897 10.325 9.35613 10.6612 9.35613C10.9975 9.35613 11.3199 9.4897 11.5577 9.72746L14.0352 12.207L16.5147 9.72641C16.7525 9.48864 17.075 9.35507 17.4112 9.35507C17.7475 9.35507 18.0699 9.48864 18.3077 9.72641C18.5455 9.96417 18.679 10.2866 18.679 10.6229C18.679 10.9591 18.5455 11.2816 18.3077 11.5194L18.3056 11.5204ZM25.4258 14C25.4258 16.2529 24.7577 18.4551 23.5061 20.3283C22.2545 22.2015 20.4755 23.6614 18.3942 24.5236C16.3128 25.3857 14.0225 25.6113 11.813 25.1718C9.6034 24.7322 7.57378 23.6474 5.98077 22.0544C4.38777 20.4614 3.30291 18.4318 2.8634 16.2222C2.42389 14.0126 2.64947 11.7224 3.5116 9.641C4.37373 7.55963 5.83369 5.78066 7.70687 4.52904C9.58005 3.27742 11.7823 2.60938 14.0352 2.60938C17.0551 2.61272 19.9504 3.81388 22.0859 5.94931C24.2213 8.08474 25.4224 10.98 25.4258 14ZM22.8945 14C22.8945 12.2478 22.3749 10.5349 21.4015 9.078C20.428 7.62108 19.0443 6.48555 17.4255 5.81501C15.8067 5.14446 14.0253 4.96901 12.3068 5.31086C10.5882 5.6527 9.00964 6.49647 7.77064 7.73548C6.53163 8.97448 5.68786 10.5531 5.34602 12.2716C5.00418 13.9902 5.17962 15.7715 5.85017 17.3903C6.52071 19.0092 7.65624 20.3928 9.11316 21.3663C10.5701 22.3398 12.2829 22.8594 14.0352 22.8594C16.384 22.8569 18.636 21.9227 20.2969 20.2617C21.9578 18.6008 22.892 16.3489 22.8945 14Z" fill="#B9B9B9"/>
                </svg>
            </div>
            <hr/>
            <div class="flex h-full">
                <div class="detailTab">
                    <span>{{ $t('integrated_checks.details') }}</span>    
                </div>
                <div class="allDetailsSections">
                    <detailOutputScreen :outputData="outputData" :updatedFilteredData="updatedFilteredData" :gdcListedCountries="gdcListedCountries" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>

<script>
import detailOutputScreen from "./expandedOutputScreen.vue"

export default {
    title: "Output Modal",
    name: "gdc-check-output-modal",
    components: {
        detailOutputScreen,
    },
    props: {
        outputData:  {
            type: Array
        },
        updatedFilteredData:  {
            type: Object
        },
        showOutputScreen: {
            type: Boolean,
            default: false
        },
        gdcListedCountries: {
            type: Array
        }
    },
    data() {
        return {
            isShowDetailCanvas: false,
        }
    },
    mounted() {
        console.log('outputData', this.outputData);
        this.isShowDetailCanvas = this.showOutputScreen
    },
    methods: {
        hideModal() {
            this.$modal.hide('output-modal');
        },
        closeDetailCanvas() {
            this.isShowDetailCanvas = false;
            this.$emit("expandOutput");
        }
    }
}
</script>

<style scoped lang='scss'>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the desired transparency */
  z-index: 1; /* Ensure the overlay is above the offcanvas */
}

.offcanvas {
  position: fixed;
  top: 0;
//   right: -300px; /* Adjust this value to control how far offcanvas is initially positioned */
  width: 75%; /* Adjust this value to control offcanvas width */
  height: 100%;
  background-color: #fff;
  transition: right 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.offcanvas-open {
  right: 0;
}

.offcanvas-content {
  padding: 10px 0 10px 0;
}

.personIcon {
    background: #E7EEF9;
    padding: 10px;
    border-radius: 50%;
    span {
        color: var(--Blue-Shade, #1A2036);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.detailTab {
    width: 214px;
    span {
        display: flex;
        background: rgba(13, 105, 213, 0.08);
        padding: 12px 24px;
        align-items: center;
        gap: 10px;
        border-right: 3px solid #0D69D5;
    }
}
.allDetailsSections {
    width:100%;
    border-left: 1px solid #F1DBDB;
    background: #FFF;
}
</style>