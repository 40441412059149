<template>
    <div class="bg-white px-4 py-4 rounded-lg flex flex-col gap-4">
        <div class="flex items-center justify-between w-full">
            <div class="flex gap-4">
                <slot name="beforeToggler"></slot>

                <button
                    v-if="showToggle"
                    type="button"
                    :disabled="open"
                    @click="showForms"
                >
                   <MultientityIcon type="openForms" :fill="openButtonFillColor" />
                </button>
            </div>

            <button
                v-if="open"
                type="button"
                @click="hideForms"
            >
                <MultientityIcon width="25" height="25" type="closeForms" fill="red" />
            </button>
        </div>

        <div
            v-if="open"
            class="flex flex-col gap-4"
        >
            <div class="flex flex-col gap-4">
                <div
                    v-for="item in parsedData"
                    :key="item.data.id"
                    :id="`dcr-form-${item.data.id}`"
                >
                    <CollectInfoForm
                        :value="item?.data || null"
                        :key="item.data.id"
                        :ref="`dcr-form-${item.data.id}`"
                        :config="getFormConfig(item.data.id)"
                        @addCollectInfoUser="$emit('addCollectInfoUser', { $event, formId: item.data.id })"
                        @input="updateDataCollection($event, item)"
                    >
                        <template #footer>
                            <div class="flex justify-end px-5 pb-5">
                                <email-scheduler
                                    v-if="showEmailScheduler(item)"
                                    :item="scheduledSequences[item?.data?.id]"
                                    class="cursor-pointer"
                                />
                            </div>
                        </template>
                    </CollectInfoForm>
              </div>

                <CollectInfoForm
                    v-if="showNewFormAdding"
                    :value="newFormModel"
                    key="newForm"
                    :config="newFormConfig"
                    @addCollectInfoUser="$emit('addCollectInfoUser', { $event, formId: 'newForm' })"
                    @input="updateNewForm($event)"
                    @onHeaderBtnClick="removeNewForm"
                />
            </div>

            <div
                v-if="showActionButtons"
                class="flex justify-end gap-4 mt-4"
            >
                <button
                    :disabled="showNewFormAdding"
                    class="flex items-center gap-2 p-3 font-bold cursor-pointer rounded-lg border-2"
                    :class="{
                        'text-primary-500 border-primary-500': !showNewFormAdding,
                        'text-gray-400 border-gray-300 cursor-none': showNewFormAdding
                    }"
                    @click="addNewForm"
                >
                    <MultientityIcon width="20" height="20" type="addForm" :fill="addButtonFillColor" />
                    <span>Add</span>
                </button>
                <button
                    :disabled="saveButtonDisabled"
                    class="p-3 font-bold rounded-lg"
                    :class="{
                        'text-white bg-primary-500 cursor-pointer': !saveButtonDisabled,
                        'text-gray-400 bg-gray-300 cursor-none ': saveButtonDisabled
                    }"
                    @click="saveForms"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import MultientityIcon from "./MultientityIcon";
const TrashIcon = () => import("@shared/assets/icons/trash-2.svg");
const CollectInfoForm = () => import("@shared/components/CollectInfoForm/index.vue");
const EmailScheduler = () => import("./EmailScheduler");
import { MAXIMUM_CUSTOMIZED_FORMS_COUNT } from "@shared/utils/constants";
import { DataCollection } from "../utils/functions";

export default {
    name: "CustomizedForms",

    components: {
        CollectInfoForm,
        EmailScheduler,
        MultientityIcon,
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        formOptions: {
            type: Array,
            default: () => [],
        },  
        userOptions: {
            type: Array,
            default: () => [],
        },
        sequenceOptions: {
            type: Array,
            default: () => [],
        },
        pending: {
            type: Boolean,
            default: false,
        },
        existingFormUsers: {
            type: Object,
            default: () => {},
        },
        scheduledSequences: {
            type: Object,
            default: () => {},
        },
        open: {
            type: Boolean,
            default: false,
        },
        showToggle: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {
            showNewFormAdding: false,
            newFormModel: {
                form: null,
                user: null,
                sequence: null,
                role: null,
            },
            parsedData: null,
        };
    },

    watch: {
        value(val) {
            this.initFormsData(val);
        },
    },

    created() {
        this.initFormsData();
    },

    computed: {
        defaultFormOptions() {
            return {
                form: this.formOptions,
                sequence: this.sequenceOptions,
            }
        },

        newFormConfig() {
            return {
                options: {
                    ...this.defaultFormOptions,
                    user: this.userOptions,
                },
                props: {
                    ...this.detailsProps,
                    sequence: { disabled: false },
                },
                header: {
                    showButton: true,
                    component: TrashIcon,
                    event: "onHeaderBtnClick",
                },
            };
        },

        detailsProps() {
            return {
                form: { disabled: true },
                user: { validation: "required" },
                sequence: { disabled: true },
            };
        },

        defaultDataCollection() {
            return this.value[0] || null;
        },

        formTypeDefaultValue() {
            return this.defaultDataCollection?.form;
        },

        sequenceDefaultValue() {
            return this.defaultDataCollection?.sequence;
        },

        isUpdatedForm() {
            return this.parsedData?.some((el) => el.updated);
        },

        isNewFormValid() {
            return !!this.newFormModel.user && !!this.newFormModel.sequence;
        },

        saveButtonDisabled() {
            return !this.isNewFormValid || !this.showNewFormAdding;
        },

        showActionButtons() {
            return this.value.length < MAXIMUM_CUSTOMIZED_FORMS_COUNT;
        },

        openButtonFillColor() {
            return this.open ? "var(--theme-color-text-100)" : "var(--theme-color-main)";
        },

        addButtonFillColor() {
            return this.showNewFormAdding ? "var(--theme-color-text-100)" : "var(--theme-color-main)";
        },
    },

    methods: {
        getFormConfig(formId) {
            return {
                options: {
                    ...this.defaultFormOptions,
                    user: this.existingFormUsers[formId],
                },
                props: this.detailsProps,
            }
        },

        initFormsData(payload = this.value) {
            if (!payload) this.parsedData = null;
            else this.parsedData = payload.map((el) => new DataCollection({ ...el }));
        },

        showForms() {
            this.$emit("toggledForms");
            this.initFormsData();
        },

        hideForms() {
            this.removeNewForm();
            this.initFormsData(null);
            this.$emit("toggledForms");
        },

        addNewForm() {
            this.showNewFormAdding = true;
            this.newFormModel.form = this.formTypeDefaultValue;
            this.newFormModel.sequence =  this.sequenceDefaultValue
        },

        updateNewForm(val) {
            this.newFormModel = val;
        },

        updateForms() {
            const updatedForms = this.parsedData
                .filter((el) => el.updated);
            const payload = updatedForms.map((el) => el.data);

            // execute after forms are saved:
            const callback = () => {
                // reset 'updated' state:
                updatedForms.forEach((el) => {
                    el.flag(false);
                });
            };

            this.$emit("input", {
                data: payload,
                callback,
            });
        },

        saveNewForm() {
            this.$emit("addNewForm", this.newFormModel);
            this.showNewFormAdding = false;
        },

        saveForms() {
            if (this.isNewFormValid) {
                this.saveNewForm();
                this.removeNewForm();
            }
        },

        removeNewForm() {
            this.showNewFormAdding = false;
            this.newFormModel.user = null;
            this.newFormModel.role = null;
        },

        updateDataCollection(payload, item) {
            const roleBeforeUpd = item.data.role;
            item.update(payload);
            const roleAfterUpd = item.data.role;

            if (!payload.user || roleBeforeUpd === roleAfterUpd) return;

            // User roles update by clicking role checkboxes instead of Save button
            this.updateForms();
        },

        showEmailScheduler(item) {
            const scheduledSequence = this.scheduledSequences?.[item?.data?.id];
            if (!scheduledSequence) return;

            return scheduledSequence?.activity_type === "email_sequence"
                && scheduledSequence?.due_days > 0;
        },
    },
}
</script>
