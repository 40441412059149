
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import Loader from "@shared/loader";
import modalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view";
// import { getChecksAssignedtoClient } from "@/modules/checks/services.js";
import { getChecksList } from "@/modules/checks/services.js";
import axios from "@/axios";

export default {
    name: "add-check-modal",
    components: {
        Loader,
        Button,
        Checkbox,
        modalContent,
        ListView,
    },
    data() {
        return {
            checkName: "",
            selectedChecks: [],
            loadingLists: false,
            allChecks: [],
            addingChecks: false,
            search: '',
            matchedCheck: "",
            selectAllVal: false,
            updateSelectAllVal: false,
            allChecksData: [],
            matchCheck: false,
            isSelectAllInput: false,
        };
    },
    props: {
        caseChecks: {
            type: Array,
            default: [],
        },
        clientId: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.closeModal();
    },
    computed: {
        checks() {
            return this.allChecks.filter((chk) => !this.caseChecks.some(el => el.check_id === chk.id))
        },
        filteredChecks() {
            return this.checks.filter((el) => el.name?.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    methods: {
        closeModal() {
            this.$refs["add-check-modal"].hideModal();
        },
        async showCheckModal() {
            this.checkName = "";
            this.search = '';
            this.$refs["add-check-modal"].showModal();
            this.loadingLists = true
            await this.fetchChecksList();
            this.loadingLists = false
        },

        async fetchChecksList() {
            // let clientId = this.$route?.query?.clientId || this.clientId;
            const tenant = this.$store.getters.getTenantId;
            let checksData = await getChecksList(tenant, 1000); //removed all check list for client
            // let clientChecksData = await getChecksAssignedtoClient(clientId);
            this.allChecks = checksData;
            // this.columns = columns;
            this.isLoading = false;
        },
        toggleCheck(check) {
            this.currentChecks = [...this.checks];
            const match = this.selectedChecks.findIndex((chk) => chk.id === check.id);
            this.matchCheck = match;
            if (match > -1) {
                this.selectedChecks.splice(match, 1);
            }
            else {
                this.selectedChecks.push(check);
            }
            if(this.selectAllVal){
                this.updateSelectAllVal = (this.selectedChecks.length != this.currentChecks.length) ? false : true;
                this.isSelectAllInput = true;
            }
        },
        selectAll(value) {
            this.updateSelectAllVal = this.matchCheck > -1 ? true : false;
            this.selectAllVal = value;
            if (value)
                this.selectedChecks = [...this.checks];
            else
                this.selectedChecks = [];
        },
        async addCheckToCase() {
            this.addingChecks = true;
            const case_id = this.$route.query.caseid;
            const url = `/case/addon-checks/${case_id}`;
            const payload = {
                addon_checks: this.selectedChecks.map((chk) => chk.id),
            }
            
            try {
                await axios.post(url, payload);
                this.$toast.success(this.$t("components.tenant.add_check.add_success"));
                this.$emit("checksAdded")
            } catch (error) {
                this.$toast.warning(error?.response?.data?.detail || this.$t("components.tenant.add_check.add_error"));
            }
            this.closeModal();
            this.addingChecks = false;
        },

        handleFilterResult(search) {
            return this.search = search;
        },
        onBeforeClose() {
            this.selectedChecks = [];
            this.currentChecks = [];
            this.updateSelectAllVal = false;
            this.selectAllVal = false;
            this.isSelectAllInput = false;
            this.$emit("before-close");
        },
    },
};