<!-- Localized -->
<template>
    <div>
        <tabs class="ml-10" @select="selectTab" :selected="tabs.selected" :tabs="tabs.list" :isVerificationCheck="true" :isTabDisable="isTabDisable"/>
        <div class="flex parentBusinessCheckDiv">
            <div v-if="tabs.selected == 'run_check' || tabs.selected  == 'output'" class="my-6 absolute queryContainer">
                
                <!-- <template v-else>
                    <div class="flex justify-center items-center w-full p-10">
                        <Loader />
                    </div>
                </template> -->
            </div>
            <div v-if="this.tabs.selected=='check_data'" class="gdcCheckFormBuilder scroll-bar flex flex-col gap-4 w-full ml-10 pt-5 pr-5 max-h-full">
                <template v-if="!CaseCheckFormPreviewLoader">
                    <div class="overflow-hidden rounded-2xl shadow-xl bg-card-bg w-full flex flex-col fixed-height" style="height: calc(100% - 48px)">
                        <FormBuilder :data="formData" :saveSectionMethod="saveSection" :removeBlockMethod="removeBlockMethod" :removeFieldMethod="removeFieldMethod" :isDBSCheck="integratedCheckSubType === 'dbs'" @submit="saveForm" :userInfo="candidateData"/>
                    </div>
                </template>
                <template v-else>
                    <div class="flex justify-center items-center">
                        <Loader />
                    </div>
                </template>
            </div>
            <div v-if="this.tabs.selected=='run_check'">
                <div v-if="!isRunCheckLoaded" class="w-full ml-10 pt-5">
                    <div class="w-full h-full flex flex-col gap-6 layout rounded-lg">
                        <div class="bg-gray-100 rounded-lg" style="width: 880px">
                            <div class="flex items-center rounded-t-lg justify-between py-1.5 bg-blue-600 text-white-text px-4 font-bold"> 
                                <div class="w-1/4"></div>
                                <div class="flex w-full justify-evenly">
                                    <div class="flex mr-7 cursor-default" @click="resetFields">
                                        <span class='m-auto'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 12 13" fill="none">
                                                <g clip-path="url(#clip0_9220_1920)">
                                                <path d="M11.7265 0.5H10.6153C10.5772 0.499957 10.5395 0.50765 10.5045 0.522612C10.4695 0.537574 10.4379 0.559494 10.4116 0.587042C10.3853 0.61459 10.3648 0.647193 10.3515 0.682873C10.3382 0.718553 10.3323 0.756567 10.3341 0.794609L10.4278 2.7343C9.88274 2.09199 9.20426 1.57615 8.43959 1.22268C7.67492 0.8692 6.84242 0.686572 6 0.6875C2.79703 0.6875 0.185158 3.30148 0.187502 6.50445C0.189845 9.71257 2.79141 12.3125 6 12.3125C7.43931 12.3145 8.8278 11.7805 9.89484 10.8146C9.92335 10.7891 9.94635 10.758 9.96244 10.7233C9.97853 10.6886 9.98737 10.651 9.98843 10.6128C9.98948 10.5745 9.98272 10.5365 9.96856 10.5009C9.95441 10.4654 9.93315 10.4331 9.90609 10.4061L9.10922 9.60922C9.05879 9.5588 8.99108 9.52942 8.91981 9.52702C8.84853 9.52463 8.77901 9.5494 8.72531 9.59632C8.09565 10.1503 7.3121 10.4992 6.47909 10.5965C5.64609 10.6938 4.80322 10.5349 4.06285 10.1409C3.32247 9.74693 2.71977 9.13665 2.33509 8.39141C1.9504 7.64618 1.80202 6.80139 1.90971 5.96967C2.01739 5.13794 2.37604 4.3588 2.93784 3.73612C3.49964 3.11343 4.2379 2.67677 5.05419 2.48437C5.87049 2.29196 6.72604 2.35294 7.5068 2.65918C8.28755 2.96542 8.95642 3.50236 9.42422 4.19844L7.04461 4.0843C7.00657 4.08249 6.96855 4.08842 6.93287 4.10175C6.89719 4.11507 6.86459 4.1355 6.83704 4.1618C6.80949 4.18809 6.78757 4.21971 6.77261 4.25474C6.75765 4.28976 6.74996 4.32746 6.75 4.36555V5.47672C6.75 5.55131 6.77963 5.62285 6.83238 5.67559C6.88512 5.72834 6.95666 5.75797 7.03125 5.75797H11.7265C11.8011 5.75797 11.8726 5.72834 11.9254 5.67559C11.9781 5.62285 12.0077 5.55131 12.0077 5.47672V0.78125C12.0077 0.706658 11.9781 0.635121 11.9254 0.582376C11.8726 0.529632 11.8011 0.5 11.7265 0.5Z" fill="#DCECFF"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_9220_1920">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <span class="ml-2 m-auto">{{ $t('integrated_checks.reset') }}</span>
                                    </div>
                                    <div class="w-2/5 mr-7">
                                        <vue-multiselect 
                                            :multiple="false" 
                                            :taggable="false" 
                                            :close-on-select="true" 
                                            :clear-on-select="true" 
                                            :preserve-search="true" 
                                            :placeholder="$t('integrated_checks.select_country')" 
                                            :show-labels="false" 
                                            :maxHeight="200" 
                                            :options="countryList"
                                            :allow-empty="false"
                                            v-model="isCountrySelected"
                                            :title="selectedCountry"
                                            @input="updateSelectedReport($event)"
                                            class="gdc_country_dropdown ml-2 h-10 relative bottom-4"> 

                                            <span
                                                slot="caret"
                                                slot-scope="{ toggle }"
                                                class="arrow"
                                                @mousedown.prevent.stop="toggle"
                                            >
                                                <font-awesome-icon
                                                class="icon"
                                                icon="chevron-down"
                                                />
                                            </span>
                                            <template slot="option" slot-scope='options'>        
                                                <div>          
                                                    <span :title='options.option'> {{ options.option.length > 22 ? options.option.substring(0,22)+ '...' : options.option }}</span>        
                                                </div>      
                                            </template>
                                        </vue-multiselect>
                                    </div>
                                    <div class="w-2/6 mr-7">
                                        <vue-multiselect 
                                            :multiple="false" 
                                            :taggable="false" 
                                            :close-on-select="true" 
                                            :clear-on-select="true" 
                                            :preserve-search="true" 
                                            :placeholder="$t('integrated_checks.type')" 
                                            :show-labels="false" 
                                            :maxHeight="200" 
                                            :options="getListedCountriesType"
                                            :allow-empty="false"
                                            v-model="selectedType"
                                            @input="updatedSelectedType($event)"
                                            class="gdc_country_dropdown ml-2 h-10 relative bottom-4"> 

                                            <span
                                                slot="caret"
                                                slot-scope="{ toggle }"
                                                class="arrow"
                                                @mousedown.prevent.stop="toggle"
                                            >
                                                <font-awesome-icon
                                                class="icon"
                                                icon="chevron-down"
                                                />
                                            </span>
                                        </vue-multiselect>
                                    </div>
                                </div>
                            </div>
                            <div v-if="filteredData['Name']?.length && filteredData['Address']?.length && filteredData['ID']?.length || !selectedCountry" class="p-4 scroll-bar flex flex-col gap-2">
                                <div v-if="!selectedCountry && isFieldsReset" class="h-80 flex flex-col justify-center items-center">
                                    <span class="font-bold text-xl">{{ $t('integrated_checks.no_data_here') }}</span>
                                    <span class="mt-2">{{ $t('integrated_checks.select_any_country_first') }}</span>
                                </div>
                                <div v-else-if="!countryList.length && !isFieldsReset" class="bg-card-bg p-5 flex justify-center items-center">
                                    <loader />
                                </div>
                                <div v-else>
                                    <div v-for="[id, items, currIndex] in Object.entries(filteredData)" :key="id" class="px-2 pt-3.5 pb-2.5 mb-2 bg-white rounded-lg flex justify-between">
                                        <div v-if="['Name', 'Address', 'ID'].includes(id)" class="flex flex-col relative top-1">
                                            <div class="flex mb-3">
                                                <input type="checkbox" class="checkbox checkbox-primary ml-2 runCheckCheckbox" @change="selectAll($event.target.checked, id)" :key="id+(currIndex)" :ref="id" :checked="true"/>
                                                <span v-if="['Name', 'Address', 'ID'].includes(id)" class="font-semibold px-2 ml-3">{{ id }}</span>
                                            </div>
                                            <div v-if="!showMultipleFields.includes(currIndex)">
                                            <div class="p-2 mb-1 rounded-lg flex justify-between items-center bg-gray-100" style="width: 832px">
                                                <div class="flex w-full ml-4">
                                                        <span v-if="id=='Name' || id=='Address'" class="font-semibold px-2 w-3/5 ml-3">{{ $t('integrated_checks.full_id', { value: id }) }}</span>
                                                        <span v-else-if="id=='ID'" class="font-semibold px-2 w-3/5 ml-3">{{ $t('integrated_checks.id') }}</span>

                                                    </div>
                                                    <span v-if="id=='Name' || id=='Address' || id=='ID'" class="pl-10 rounded text-center w-11/12 relative bottom-0.5">
                                                        <div v-if="id=='Name'">
                                                            <vue-multiselect  
                                                                :title="!currentName ? filteredData['Name'][0].full_name : currentName"
                                                                :multiple="false" 
                                                                :taggable="false" 
                                                                :close-on-select="true" 
                                                                :clear-on-select="true" 
                                                                :preserve-search="true" 
                                                                :placeholder="$t('integrated_checks.select_name')" 
                                                                :show-labels="false" 
                                                                :maxHeight="200" 
                                                                :options="selectedVal('Name')"
                                                                :allow-empty="false"
                                                                v-model="selectedName"
                                                                @input="updateName($event, id)"
                                                                class="relative right-7 userDetails"> 

                                                                <span
                                                                    slot="caret"
                                                                    slot-scope="{ toggle }"
                                                                    class="arrow"
                                                                    @mousedown.prevent.stop="toggle"
                                                                >
                                                                    <font-awesome-icon
                                                                    class="icon"
                                                                    icon="chevron-down"
                                                                    />
                                                                </span>

                                                                <template slot="option" slot-scope='options'>        
                                                                    <div>          
                                                                        <span :title='options.option'> {{ options.option.length > 35 ? options.option.substring(0,35)+'...' : options.option }}</span>        
                                                                    </div>      
                                                                </template>
                                                            </vue-multiselect>
                                                        </div>
                                                        <div v-if="id=='Address'">
                                                            <vue-multiselect 
                                                                :title="!currentAddress ? filteredData['Address'][0]?.title : currentAddress"
                                                                :multiple="false" 
                                                                :taggable="false" 
                                                                :close-on-select="true" 
                                                                :clear-on-select="true" 
                                                                :preserve-search="true" 
                                                                :placeholder="$t('integrated_checks.select_address')" 
                                                                :show-labels="false" 
                                                                :maxHeight="200" 
                                                                :options="selectedVal('Address')"
                                                                :allow-empty="false"
                                                                v-model="selectedAddress"
                                                                @input="updateAddress($event, id)"
                                                                class="relative right-7 userDetails"> 

                                                                <span
                                                                    slot="caret"
                                                                    slot-scope="{ toggle }"
                                                                    class="arrow"
                                                                    @mousedown.prevent.stop="toggle"
                                                                >
                                                                    <font-awesome-icon
                                                                    class="icon"
                                                                    icon="chevron-down"
                                                                    />
                                                                </span>

                                                                <template slot="option" slot-scope='options'>        
                                                                    <div>          
                                                                        <span :title='options.option'> {{ options.option.length > 42 ? options.option.substring(0,42)+'...' : options.option }}</span>     
                                                                    </div>      
                                                                </template>
                                                            </vue-multiselect>
                                                        </div>
                                                        <div v-if="id=='ID'" class="flex items-center">
                                                            <vue-multiselect 
                                                                :multiple="false" 
                                                                :taggable="false" 
                                                                :close-on-select="true" 
                                                                :clear-on-select="true" 
                                                                :preserve-search="true" 
                                                                :placeholder="$t('integrated_checks.select_id')" 
                                                                :show-labels="false" 
                                                                :maxHeight="200" 
                                                                :options="selectedVal('ID')"
                                                                :allow-empty="false"
                                                                v-model="selectedId"
                                                                :title="!currentId ? filteredData['ID'][0].idName : currentId"
                                                                @input="updateId($event, id)"
                                                                class="relative right-7 userDetails idDropdown"> 

                                                                <span
                                                                    slot="caret"
                                                                    slot-scope="{ toggle }"
                                                                    class="idArrow"
                                                                    @mousedown.prevent.stop="toggle"
                                                                >
                                                                    <font-awesome-icon
                                                                    class="icon"
                                                                    icon="chevron-down"
                                                                    />
                                                                </span>

                                                                <template slot="option" slot-scope='options'>        
                                                                    <div>          
                                                                        <span :title='options.option'> {{ options.option.length > 15 ? options.option.substring(0,15)+'...' : options.option }}</span>    
                                                                    </div>      
                                                                </template>
                                                            </vue-multiselect>
                                                            <div class="p-2"></div>
                                                            <vue-multiselect 
                                                                :multiple="false" 
                                                                :taggable="false" 
                                                                :close-on-select="true" 
                                                                :clear-on-select="true" 
                                                                :preserve-search="true" 
                                                                :placeholder="$t('integrated_checks.select_value')"
                                                                :show-labels="false"
                                                                :maxHeight="200" 
                                                                :options="selectCompIdVal()"
                                                                :allow-empty="false"
                                                                v-model="selectedIdVal"
                                                                :title="!currentIdVal ? filteredData['ID'][0][filteredData['ID'][0]?.idName] : currentIdVal"
                                                                @input="updateIdVal($event, id)"
                                                                class="relative right-7 userDetails idValDropdown"> 

                                                                <span
                                                                    slot="caret"
                                                                    slot-scope="{ toggle }"
                                                                    class="idArrowVal"
                                                                    @mousedown.prevent.stop="toggle"
                                                                >
                                                                    <font-awesome-icon
                                                                    class="icon"
                                                                    icon="chevron-down"
                                                                    />
                                                                </span>
                                                                <template slot="option" slot-scope='options'>        
                                                                    <div>          
                                                                        <span :title='options.option'> {{ options.option.length > 15 ? options.option.substring(0,15)+'...' : options.option }}</span>    
                                                                    </div>      
                                                                </template>
                                                            </vue-multiselect>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="flex justify-between" style="width: 832px">
                                            <div class="flex w-full relative top-1">
                                                <input type="checkbox" class="checkbox checkbox-primary ml-2 runCheckCheckbox" @change="selectSingleFields($event.target.checked, id)" :ref="id" :checked="setCheckState(id)"/>
                                                <span class="font-semibold px-2 w-3/5 ml-3">{{ id }}</span>
                                            </div>
                                            <span class="mr-9 py-1 rounded-lg h-10 bg-custom-gray text-center bg-gray-100 relative bottom-0.5 singleInfoFields"> 
                                                <span :title="Object.values(items)[0]">{{ Object.values(items)[0] }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="bg-card-bg p-5 flex justify-center items-center">
                                <loader />
                            </div>
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="flex justify-center items-center w-full p-10">
                        <Loader />
                    </div>
                </template>
                <div class="w-full flex mt-2 runGdcButton">
                    <button :class="!selectedCountry || isFieldsReset || activateLoader || (!filteredData['Name']?.length && !filteredData['Address']?.length && !filteredData['ID']?.length) ? 'bg-gray-300' : 'bg-blue-600'" class="py-2 px-3 rounded-lg ml-auto text-white font-bold w-52 flex justify-center items-center" :disabled="isFieldsReset || !selectedCountry || activateLoader || (!filteredData['Name']?.length && !filteredData['Address']?.length && !filteredData['ID']?.length)" @click="runCheck">
                        {{ $t('integrated_checks.run_check') }}
                        <template>
                            <div v-if="activateLoader" class="flex justify-center items-center ml-4">
                                <Loader class='runCheckLoader'/>
                            </div>
                        </template>
                    </button>
                </div>
            </div>
            <div v-if="this.tabs.selected=='output'">
                <caseOutput :outputData="getOutputData" :updatedFilteredData="filteredData" @backToSearch="backToSearch" :gdcListedCountries="gdcListedCountries"/>
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import axios from "@shared/light-ray/axios";
import osintAxios from "@shared/light-ray/axios/osint-axios";
import {default as blackboxAxios} from "@/axios";
import VueCookies from "vue-cookies";
import tabs from "@/components/dashboard-components/tabs";
import FormBuilder from "@/components/input-form-builder";
import caseOutput from "./idVerifyOutputTab.vue";
import Loader from "@/components/loader";
import {mapActions} from "vuex"

export default {
    title: "GDC Check",
    name: "gdc-check",
    components: {
        tabs,
        FormBuilder,
        VueMultiselect,
        Loader,
        caseOutput,
    },
    props: {
        CaseCheckFormPreviewData: {
            type: Object,
        },
        CaseCheckFormPreviewLoader: {
            type: Boolean,
        },
        saveSection: {
            type: Function,
        },
        integratedCheckSubType: {
            type: String,
        },
        saveForm: {
            type: Function,
        },
        candidateData: {
            type: Object,
        },
        clientId: {
            type: String,
        },
        nonRefCheckData: {
            type: Object,
        },
        removeBlockMethod: {
            type: Function,
        },
        removeFieldMethod: {
            type: Function,
        },
        isClient: {
            type: Boolean,
        },
    },
    data() {
        return {
            tabs: {
                selected: "Run Checks",
                list: [
                    {
                        id: "check_data",
                        label: this.$t('integrated_checks.check_data'),
                    },
                    {
                        id: "run_check",
                        label: this.$t('integrated_checks.run_check'),
                    },
                    {
                        id: "output",
                        label: this.$t('integrated_checks.output'),
                    },
                ],
            },
            countryList: [],
            selectedCountry: null,
            selectedType: null,
            outputData: [],
            showMultipleFields: [],
            isDropdownRotate: false,
            isAllCheckboxSelected: false,
            tenantId: null,
            isFieldsReset: false,
            gdcListedCountries: [],
            filteredData: {},
            fullAddress: "",
            user_details: null,
            gdcLoading: false,
            selectedGDCData: [],
            updatedSelectedGdcVal: [],
            isRunCheckSelected: false,
            selectedCountryCode: "",
            selectedName: null,
            selectedAddress: null,
            currentAddress: null,
            selectedId: null,
            selectedIdVal: null,
            queryDataFiltered: null,
            activateLoader: false,
            searchQueryData: null,
            isRunCheckLoaded: false,
            isRunCheckedClicked: false,
            isSelectAllChecked: false,
            isSingleFieldChecked: false,
            currentSelectAllId: '',
            currentSingleSelectId: '',
            currentName: false,
            currentId: '',
            currentIdVal: '',
            caseId: '',
            isCurrentDivClicked: false,
            currentClientId: '',
            currentOutputId: -1,
            caseAndCheckData: {},
            isTabDisable: false,
            isNationalIdValid: false,
            isNationalIdValValid: false,
        };
    },
    async mounted() {
        await this.getToolsStatus()
        this.currentClientId = this.clientId
        this.caseId = this.$route.params.caseid
        this.tabs.selected = "run_check";
        this.caseAndCheckData = this.CaseCheckFormPreviewData
        this.tenantId = this.$store.getters.getTenantId;
        this.user_details = this.$store.getters.getUserDetails;
        await this.selectTab(this.tabs.selected);
    },
    watch: {
        selectedId(newVal,oldVal){
            if(this.isNationalIdValid) {
                this.selectedId = oldVal.length > 11 ? oldVal.substring(0,11) + '...' : oldVal; 
            }
            else this.selectedId = newVal.length > 11 ? newVal.substring(0,11) + '...' : newVal; 
            this.isNationalIdValid = false;
        },
        selectedIdVal(newVal,oldVal){
            if(this.isNationalIdValValid) {
                this.selectedIdVal = oldVal && oldVal !== null && oldVal.length > 11 ? oldVal.substring(0,11) + '...' : oldVal; 
            }
            else this.selectedIdVal = newVal && newVal !== null && newVal.length > 11 ? newVal.substring(0,11) + '...' : newVal; 
            this.isNationalIdValValid = false;
        },
    },
    computed: {
        getGdcListedCountries() {
            const allSecBlocks = this.caseAndCheckData?.sections?.find(el => el.entities)?.entities || []
            const addressDetails = allSecBlocks?.find(el => el.block_name == 'Address Details')?.entities
                    
            const updateFilteredData =  new Set();

            addressDetails?.forEach(data => { 
                if (data?.field_internal_name === "country" && data?.options) { 
                    const matchingOption = data?.options.find(optVal => optVal?.option_value === data?.value);
                    if (matchingOption) {
                        data.actual_value = matchingOption?.option_label;
                        updateFilteredData.add(data.actual_value);
                    }
                }
            });

            return Array.from(updateFilteredData); 
        },
        getListedCountriesType() {
            let gdcCountries = [...this.gdcListedCountries];
            let verificationType = [];
            let countryType = gdcCountries.find(el => this.selectedCountry == el?.country_name);
            if(countryType) verificationType =  countryType.verification_type;
            return verificationType;
        },
        isCountrySelected() {
            if(this.selectedCountry && this.selectedCountry.length > 18) return this.selectedCountry.substring(0,18)+'...';
            else return this.selectedCountry
        },
        getOutputData() {
            return this.outputData;
        },
        formData(){
            return this.caseAndCheckData;
        },
    },
    methods: {
        ...mapActions(["getToolsStatus"]),
        getAllCountries() {
            // const allSecBlocks = this.caseAndCheckData?.sections?.find(el => el.entities)?.entities || []
            
            const sectionsArray = this.caseAndCheckData?.sections || [];
            const flattenedArray = sectionsArray.flatMap(section => section.entities || []);
            const addressDetails = flattenedArray?.find(el => el.block_name == 'Address Details')?.entities

            const updateFilteredData =  new Set();
            addressDetails?.forEach(data => { 
                if (data?.field_internal_name === "country" && data?.options) { 
                    const matchingOption = data?.options.find(optVal => optVal?.option_value === data?.value);
                    if (matchingOption) {
                        data.actual_value = matchingOption?.option_label;
                        updateFilteredData.add(data.actual_value);
                    }
                }
            });

            this.countryList =  Array.from(updateFilteredData); 
            this.countryList = [...new Set(this.countryList)];
            this.selectedCountry = this.countryList?.[0]
        },
        getKey(id,data,currentIndex) {
            if(id == 'Name') {
                return 'Name '+(currentIndex+1)
            }
            else if(id == 'Address') {
                return 'Address '+(currentIndex+1) 
            }
            else if(id == 'ID' && data.isVisible == true ) {
                return data.idName
            }
        },
        setCheckState(id) {
            if(id == 'Email Id') {
                if(this.filteredData[id].email) return true;
                else return false;
            }
            if(id == 'Phone Number') {
                if(this.filteredData[id].phone) return true;
                else return false;
            }
            if(id == 'Date of Birth') {
                if(this.filteredData[id].dob) return true;
                else return false;
            }
        },
        resetFields() {
            this.isFieldsReset = true;
            this.selectedCountry = ""
            this.selectedType = ""
        },
        // setCheckState(id) {
        //     if(id == 'Email Id') {
        //         if(this.filteredData[id].email) return true;
        //         else return false;
        //     }
        //     if(id == 'Phone Number') {
        //         if(this.filteredData[id].phone) return true;
        //         else return false;
        //     }
        //     if(id == 'Date of Birth') {
        //         if(this.filteredData[id].dob) return true;
        //         else return false;
        //     }
        // },
        async fetchCaseCheckFormPreview() {
            const case_id = this.$route.query.caseid;
            const tenant_check_id = this.nonRefCheckData.key;
            try {
                const { data } = await axios(`case/form/${case_id}/entity/${this.$route.query.entityId}?tenant_check_id=${tenant_check_id}`);
                data.sections = data.sections?.map((entities) => {
                    entities.entities = entities.entities?.map((block) => {
                        if(block?.form_entity_type == 'BLOCK' && block.block_fe_id == null) {
                            block.block_fe_id = block?.block_id;
                        }
                        if(block?.form_entity_type == 'BLOCK' && block?.block_fe_id == block?.block_id) {
                            block.is_og_block = true;
                        }
                        return block;
                    })
                    return entities
                })
                this.filteredGDCCheckData(data)
                this.$emit('getGdcIdFilteredData',this.filteredData)
                this.isRunCheckLoaded = false;

            } catch (error) {
                this.isRunCheckLoaded = false;
                this.isFieldsReset = true
                console.log(error); 
            }
        },
        async fetchCaseCheckFormPreviewFromClient() {
            const case_id = this.$route.query.caseid;
            const tenant_check_id = this.nonRefCheckData.key;
            try {
                this.isRunCheckLoaded = true;
                const { data } = await axios(`case/form/${case_id}/client?tenant_check_id=${tenant_check_id}`);
                data.sections = data.sections?.map((entities) => {
                    entities.entities = entities.entities?.map((block) => {
                        if(block?.form_entity_type == 'BLOCK' && block.block_fe_id == null) {
                            block.block_fe_id = block?.block_id;
                        }
                        if(block?.form_entity_type == 'BLOCK' && block?.block_fe_id == block?.block_id) {
                            block.is_og_block = true;
                        }
                        return block;
                    })
                    return entities
                })
                this.filteredGDCCheckData(data)
                this.$emit('getGdcIdFilteredData',this.filteredData)
                this.isRunCheckLoaded = false;

            } catch (error) {
                this.isRunCheckLoaded = false;
                this.isFieldsReset = true
                console.log(error); 
            }
        },
        getFullDetails(id,data) {
            if(id == 'Name') {
                return data?.first_name+' '+data?.middle_name+' '+data?.last_name
            }
            else if(id == 'Address') {
                this.fullAddress =  data?.houseNumber+' '+data?.addressLine1+' '+data?.addressLine2+' '+data?.locality+' '+data?.postalCode
                return this.fullAddress.length > 30 ? this.fullAddress.substring(0,29)+'...' : this.fullAddress
            }
            else if(id == 'ID' && data.isVisible == true ) {
                return data[data.idName]
            }
        },
        updateName(name, id) {
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentName = name;
            this.selectedName = name.length > 35 ? name.substring(0,35)+'...' : name;
        },
        updateAddress(address, id) {
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentAddress = address;
            this.selectedAddress = address.length > 40 ? address.substring(0,40)+'...' : address;

        },
        updateId(idName, id) {
            this.isNationalIdValid = false;
            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let nationalId = selectedCountry?.national_id_details?.find(el => el.name == idName)?.name;
            if(!nationalId) {
                this.$toast.error(' This ID cannot be used for verification, please select other option')
                this.isNationalIdValid = true;
                return;
            }
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentId = idName;
            this.selectedId = idName && idName !== null && idName.length > 10 ? idName.substring(0,10) + '...' : idName ; 
            if(this.selectedId) {
                for( const el of this.filteredData?.['ID']) {
                     if(idName == el?.idName){
                        this.currentIdVal = el[el?.idName]
                        this.selectedIdVal =  el[el?.idName] &&  el[el?.idName] !== null && el[el?.idName].length > 10 ? el[el?.idName].substring(0,10) + '...' : el[el?.idName]
                        break;
                    }
                }
                // this.filteredData?.['ID'].forEach(el => {
                //     if(idName == el?.idName){
                //     this.currentIdVal = el[el?.idName]
                //     this.selectedIdVal =  el[el?.idName] &&  el[el?.idName] !== null && el[el?.idName].length > 10 ? el[el?.idName].substring(0,10) + '...' : el[el?.idName]
                //     }
                // })
            }
        },
        updateIdVal(idVal, id) {
            this.isNationalIdValValid = false;
            let idKey = this.filteredData['ID'].find(el => el[el.idName] == idVal)?.idName
            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let nationalId = selectedCountry?.national_id_details?.find(el => el.name == idKey)?.name;
            if(!nationalId) {
                this.$toast.error(this.$t('integrated_checks.error_id_cannot_for_verification'))
                this.isNationalIdValValid = true;
                return;
            }
            this.$refs[id][0].checked = false;
            this.selectAll(false, id);
            this.currentIdVal = idVal;
            this.selectedIdVal = idVal && idVal !== null && idVal.length > 10 ? idVal.substring(0,10) + '...' : idVal ;
            if(this.selectedIdVal) {
                for (const el of this.filteredData?.['ID']) {
                    if(el[el?.idName] == this.currentIdVal) {
                        this.currentId = el?.idName
                        this.selectedId = el?.idName && el?.idName !== null && el?.idName.length > 10 ? el?.idName.substring(0,10)+'...' : el?.idName;
                        break;
                    }
                }
                // this.filteredData?.['ID'].map(el => {
                //     if(el[el?.idName] == this.currentIdVal) {
                //         this.currentId = el?.idName
                //         this.selectedId = el?.idName && el?.idName !== null && el?.idName.length > 10 ? el?.idName.substring(0,10)+'...' : el?.idName;
                //     }
                // })
            }
        },
        // isSelectAll(id) {
        //     return this.filteredData[id].every(el => el.checked);
        // },
        async selectTab(id) {
            if(this.isTabDisable && (id == 'check_data' || id == 'output')) {
                return;
            }
            this.tabs.selected = id;
            if(this.tabs.selected == 'run_check') {
                this.isTabDisable = true;
                this.getAllCountries();
                this.filteredData = {};
                this.currentName = '';
                this.currentAddress = '';
                this.currentId = '';
                this.currentIdVal = ''
                this.currentOutputId = -1;
                await this.getSaasToken();
                await this.getQueryData();
                if(this.isClient) {
                    if(this.caseAndCheckData?.sections && !this.CaseCheckFormPreviewLoader) await this.fetchCaseCheckFormPreviewFromClient();
                }
                else {
                    if(this.caseAndCheckData?.sections && !this.CaseCheckFormPreviewLoader) await this.fetchCaseCheckFormPreview();
                }
                this.isTabDisable = false;
                if (this.filteredData?.['ID'] && this.filteredData?.['ID'].length > 0) {
                    this.selectedId = this.truncateAndAddDots(this.filteredData?.['ID']?.[0]?.idName, 12)
                    this.selectedIdVal = this.truncateAndAddDots(this.filteredData?.['ID']?.[0]?.[this.filteredData?.['ID']?.[0]?.idName], 12);
                    this.selectedName = this.truncateAndAddDots( this.filteredData?.Name?.[0]?.full_name, 35);
                    this.selectedAddress = this.truncateAndAddDots(this.filteredData?.Address[0]?.full_address, 35);
                    this.currentId = this.filteredData?.['ID']?.[0]?.idName;
                    this.currentIdVal = this.filteredData?.['ID']?.[0]?.[this.filteredData?.['ID']?.[0]?.idName];
                }
                // this.selectedId = this.filteredData?.['ID']?.[0]?.idName?.length > 12 ? this.filteredData?.['ID']?.[0]?.idName?.substring(0,12) + "..." : this.filteredData?.['ID']?.[0]?.idName;
                // this.selectedIdVal = this.filteredData?.['ID']?.[0]?.[this.filteredData?.['ID']?.[0]?.idName].length > 12 ? this.filteredData?.['ID']?.[0]?.[this.filteredData?.['ID']?.[0]?.idName].substring(0,12) + "..." : this.filteredData?.['ID']?.[0][this.filteredData?.['ID']?.[0]?.idName];
                // this.currentId = this.filteredData?.['ID']?.[0]?.idName;
                // this.currentIdVal = this.filteredData?.['ID']?.[0]?.[this.filteredData?.['ID']?.[0]?.idName];
                // this.selectedName = this.filteredData?.Name?.[0]?.full_name.length > 35 ? this.filteredData?.Name[0]?.full_name.substring(0,35)+'...' : this.filteredData?.Name[0]?.full_name;
                // this.selectedAddress = this.filteredData?.Address[0]?.full_address.length > 40 ? this.filteredData?.Address[0]?.full_address.substring(0,40)+'...' : this.filteredData?.Address[0]?.full_address ;
                const fieldsToCheck = ['Address', 'Date of Birth', 'Email Id', 'ID', 'Phone Number', 'Name'];

                fieldsToCheck.forEach(field => {
                    if (this.filteredData?.[field]?.length > 0) {
                        if (Array.isArray(this.filteredData[field])) {
                            this.filteredData[field][0].checked = true;
                        } 
                    }
                });
            }
            if(this.isFieldsReset)
                (this.tabs.selected == 'output'|| this.tabs.selected == 'check_data') ? this.isFieldsReset = false : this.isFieldsReset = true; 
            this.$emit('selectedGDCTab', id);
            this.$emit('gdcCheckData', this.filteredData);
            if(this.tabs.selected == 'output' && !this.isRunCheckedClicked && this.currentOutputId < 0) {
                let payload = this.queryDataFiltered[0]?.query
                try {
                    this.activateLoader = true;
                    // const token = VueCookies.get("saas-token")
                    const token = VueCookies.get("token")
                    await this.verifyPersonIdentity(token, payload, this.queryDataFiltered[0]?.query, false);
                    this.activateLoader = false
                } catch(error) {
                    this.activateLoader = false
                    this.$toast.error(error?.response?.data?.detail[0]?.msg);
                }
                this.currentOutputId = 0;
            }
        },
        updateSelectedReport(country){
            this.selectedCountry = country;
            const fieldsToClear = ['Name', 'Address', 'ID', 'Phone Number', 'Email Id', 'Date of Birth'];

            fieldsToClear.forEach(fieldName => {
                if (this.$refs[fieldName]?.length > 0) this.$refs[fieldName][0].checked = false;
                if (fieldName === 'Date of Birth' || fieldName === 'Email Id' || fieldName === 'Phone Number') {
                    this.selectSingleFields(false, fieldName);
                } else {
                    this.selectAll(false, fieldName);
                }
            });

            let compIdVal = [];
            this.gdcLoading = true
            this.isFieldsReset = false
            this.filteredGDCCheckData(this.caseAndCheckData);
            this.filteredData?.['ID']?.map(el => {
                if(el?.currentCountry == country)
                    compIdVal.push({'id': el?.idName, 'value':el[el?.idName]})
            });
            if(compIdVal.length > 0) {
                this.selectedId = compIdVal[0].id.length > 11 ? compIdVal[0].id.substring(0,11) + "..." : compIdVal[0].id
                this.selectedIdVal = compIdVal[0].value.length > 12 ? compIdVal[0].value.substring(0,12) + "..." : compIdVal[0].value
            } 
            else {
                this.selectedId = "";
            }
            this.filteredData['Address'].map(el => {
                if(el.country == country) {
                    this.currentAddress = el.title
                    return;
                }
            })
            this.filteredData['ID'] = this.filteredData['ID'].map(el => {
                if(el.currentCountry != country) {
                    el.isVisible = false
                } else {
                    el.isVisible = true
                }
                return el;
            })
            this.selectedAddress = this.filteredData?.['Address'].find(el => el?.title == this.currentAddress)?.full_address;
            this.gdcLoading = false
        },
        updatedSelectedType(type){
            if(this.isSelectAllChecked && this.currentSelectAllId) {
                this.$refs[this.currentSelectAllId][0].checked = true;
                this.selectAll(true, this.currentSelectAllId);
            }
            if(this.isSingleFieldChecked && this.currentSingleSelectId) {
                this.$refs[this.currentSingleSelectId][0].checked = true;
                this.selectSingleFields(true, this.currentSingleSelectId);
            }
            this.selectedType = type;
        },   
        showPersonalData(index) {
            if(this.showMultipleFields.includes(index)){
                let currentIndex = this.showMultipleFields.indexOf(index)
                if(currentIndex != -1){
                    this.showMultipleFields.splice(currentIndex,1)
                }
            }
            else {
                this.showMultipleFields.push(index);
            }
        },
        selectAll(e, id){
            this.isSelectAllChecked = e;
            this.currentSelectAllId = id;
            if (e) {
                this.filteredData?.[id]?.map(el => {
                    if(id == 'Name') {
                        if(el?.name == this.selectedName) el.checked = true;
                    }
                    else if(id == 'Address') {
                        if(el?.full_address == this.selectedAddress) el.checked = true;
                    } 
                    else if(id == 'ID') {
                        if(el[el?.idName] == this.currentIdVal) el.checked = true;
                    }
                    else if(id == 'Email Id') {
                        if(el[el?.idName] == this.currentIdVal) el.checked = true;
                    }
                    else if(id == 'Phone Number') {
                        if(el[el?.idName] == this.currentIdVal) el.checked = true;
                    }
                    return el;
                })
            } else {
                this.filteredData?.[id]?.map(el => {
                    el.checked = false
                    return el;
                })
            }
        },
        selectedVal(id) {
            let compIdVal = [];
            if(id=='Name') return Object.values(this.filteredData?.Name?.map(el => el?.full_name));
            if(id=='Address') return Object.values(this.filteredData?.Address?.map(el => el?.title));
            // if(id=='Name'){
            //     this.filteredData?.['Name']?.map(el => {
            //         if(el?.full_name.trim()) compIdVal.push(el?.full_name)
            //     });
            // }
            // if(id=='Address'){
            //     this.filteredData?.['Address']?.map(el => {
            //         if(el?.title.trim()) compIdVal.push(el?.title)
            //     });
            // }
            if(id =='ID') {
                this.filteredData?.['ID']?.forEach(el => {
                    if(el?.currentCountry == this.selectedCountry)
                        compIdVal.push(el?.idName)
                });
                if(compIdVal?.length > 0) {
                    return compIdVal
                } 
                else {
                    this.selectedId = "";
                    return ['List is empty']
                }
            }    
        },
        selectCompIdVal() {
            let compIdVal = [];
            this.filteredData?.['ID']?.map(el => {
                if(el?.currentCountry == this.selectedCountry)
                    if(el[el?.idName]) compIdVal.push(el[el?.idName])
            });
            if(compIdVal?.length > 0) {
                return compIdVal
            }
            else {
                this.selectedIdVal = ""
                 return ['List is empty']
            }
        },
        selectSingleFields(e, id) {
            this.isSingleFieldChecked = e;
            this.currentSingleSelectId = id
            this.filteredData[id].checked = e;
        },
        async getSaasToken() {
            const payload = {
                client_id: this.clientId ? this.clientId : sessionStorage.client_id
            }
            try {
                const url = `/user/saas-token`
                const data = await axios.post(url, payload)
                VueCookies.set("saas-token", data.data.token)
                await this.getCountryList();
            }
            catch(error) {
                console.log(error);
                this.$toast.error( error?.response?.data?.detail[0]?.msg || this.$t('integrated_checks.error_get_saas_token'))
            }
        },
        async getCountryList() {
            try {
                const url = `api/v1/gdc/countries`
                const data = await osintAxios.get(url,
                {
                    headers: {                       
                           "x-entity-id": this.$route.query.entityId,
                           "x-wlp-reference": `${process.env.VUE_APP_BACKEND_URL}`
                    },
                })
                this.gdcListedCountries = data.data.data.country_details;
                // if(this.gdcListedCountries) this.selectedCountry = this.getGdcListedCountries?.[0]
            }
            catch(error) {
                console.log(error)
                this.$toast.error(this.$t('integrated_checks.error_fetch_countries'))
            }
        }, 
        async runCheck() {
            this.isRunCheckedClicked = true;
            this.activateLoader = false;
            this.isCurrentDivClicked = false;
            let isSelectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            if(!isSelectedCountry && this.getListedCountriesType.length == 0) {
                this.$t('integrated_checks.error_run_check_selected_country')
                return;
            }  
            if(!this.selectedType) {
                this.$t('integrated_checks.error_select_type')
                return;
            }

            if(this.isSelectAllChecked && this.currentSelectAllId) {
                this.$refs[this.currentSelectAllId][0].checked = true;
                this.selectAll(true, this.currentSelectAllId);
            }
            if(this.isSingleFieldChecked && this.currentSingleSelectId) {
                this.$refs[this.currentSingleSelectId][0].checked = true;
                this.selectSingleFields(true, this.currentSingleSelectId);
            }

            let checkedId =  this.filteredData?.['ID']?.filter(el => el.checked);

            const selectedCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry)
            let nationalId = selectedCountry?.national_id_details?.find(el => el.name == this.currentId)?.name;
            if(!nationalId && checkedId.length) {
                this.$toast.error(this.$t('integrated_checks.error_id_cannot_for_verification'))
                return;
            }

            let checkedNames = this.filteredData?.['Name']?.filter(el => el.checked);
            let checkedAddress = this.filteredData?.['Address']?.filter(el => el.checked);
            let isCheckedEmail = this.filteredData?.['Email Id']?.checked;
            let isCheckedPhone = this.filteredData?.['Phone Number']?.checked;
            let isCheckedDOB = this.filteredData?.['Date of Birth']?.checked;

            let checkedEmail = this.filteredData?.['Email Id']?.checked == true ? this.filteredData?.['Email Id']?.['email'] : "";
            let checkedPhone = this.filteredData?.['Phone Number']?.checked == true ? this.filteredData?.['Phone Number']?.['phone'] : "";
            let checkedDOB = this.filteredData?.['Date of Birth']?.checked == true ? this.filteredData?.['Date of Birth']?.['dob'] : "";

            if(!checkedNames.length && !checkedAddress.length && !isCheckedEmail && !isCheckedPhone && !isCheckedDOB && !checkedId.length ) {
                this.$toast.error("Atleast one field is required to run the check!")
                return;
            }

            let payloadobj = {
                sources: ["gdc"],
                address: {
                    "addressLine1": "",
                    "addressLine2": "",
                    "houseNumber": "",
                    "houseNumberAddition": "",
                    "thoroughfare": "",
                    "locality": "",
                    "postalCode": "",
                    "district": "",
                    "province": "",
                    "countryCode": ""
                },
                identity: {
                    "first_name": "",
                    "middle_name": "",
                    "last_name": "",
                    nationalid: [],
                    "dob": ""
                },
                phone: {
                    phone_number: ""
                },
                email: {
                    full_email_address: ""
                },
                verification_type: ""
            };
            
            payloadobj.identity.first_name = checkedNames?.[0]?.first_name
            payloadobj.identity.middle_name = checkedNames?.[0]?.middle_name
            payloadobj.identity.last_name = checkedNames?.[0]?.last_name
            payloadobj.identity.dob = checkedDOB.replace(/-/g, '/')

            payloadobj.address.addressLine1 = checkedAddress?.[0]?.addressLine1
            payloadobj.address.addressLine2 = checkedAddress?.[0]?.addressLine2
            payloadobj.address.houseNumber = checkedAddress?.[0]?.houseNumber
            payloadobj.address.houseNumberAddition = checkedAddress?.[0]?.houseNumberAddition
            payloadobj.address.thoroughfare = checkedAddress?.[0]?.thoroughfare
            payloadobj.address.locality = checkedAddress?.[0]?.locality
            payloadobj.address.postalCode = checkedAddress?.[0]?.postalCode
            payloadobj.address.district = checkedAddress?.[0]?.district
            payloadobj.address.province = checkedAddress?.[0]?.province
            payloadobj.address.countryCode = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry).country_code

            payloadobj.phone.phone_number = checkedPhone ? checkedPhone : ""
            payloadobj.email.full_email_address = checkedEmail ? checkedEmail : ""

            payloadobj.verification_type = this.selectedType

            let selectedIds = this.filteredData['ID'].filter(el => el.checked && this.currentIdVal == el[el?.idName]);
            let currentGDCCountry = this.gdcListedCountries?.find(el => el.country_name == this.selectedCountry);
            selectedIds.map(el => {
                let idIndicator = currentGDCCountry?.national_id_details?.find(nnlId => nnlId.name == el.idName)?.indicator
                payloadobj?.identity?.nationalid.push({
                    id: el[el.idName],
                    indicator: idIndicator
                })
            }) 
            
            try {
                this.activateLoader = true;
                let query_id =  this.caseId;
                // const token = VueCookies.get("saas-token")
                const token = VueCookies.get("token")
                const url = `api/v1/gdc/person/search_sources`;
                const data = await osintAxios.post(url, payloadobj, {
                headers: {
                        'Authorization': `Bearer ${token}`,
                        'x-visited': false,
                        'x-monitoring': false,
                        'x-user-id': this.user_details.id,
                        'x-case-id': query_id,
                        'x-tool-name': 'gdc-tool',
                        'x-status': 'RUNNING',
                        "x-entity-id": this.$route.query.entityId
                    },  
                })
                if (data) {
                    await this.verifyPersonIdentity(token, payloadobj, data.data);
                    this.activateLoader = false
                    await this.selectTab('output');
                }
            
            } catch(error) {
                this.activateLoader = false
                let message = "";
                const errorMessage = error?.response?.data?.detail[0]?.msg || "";
                const fieldName = error?.response?.data?.detail[0]?.loc?.[2] || "";
                if(errorMessage && fieldName) {
                    message = errorMessage.replace("this", fieldName);
                }
                this.$toast.error(message || this.$t('general.default_error'));
            }
            this.isSelectAllChecked = false;
            this.isSingleFieldChecked = false;
            this.currentSelectAllId = '';
            this.currentSingleSelectId = '';
        },
        async verifyPersonIdentity(token,payloadData,responseData, cacheBypass=true) {
            let query_id =  this.caseId;
            const payload = payloadData;
            this.outputData = [];
            payload['request_id'] = responseData.request_id;
            payload['saved'] = false;
            payload['monitoring_status'] = false;
            payload['cache_bypass'] = cacheBypass;
            payload['cache_only'] = false;
            payload['refresh'] = cacheBypass;
            payload['visited'] = false;
            payload['monitoring'] = false;
            payload['user-id'] = this.user_details.id;
            payload['case_id'] = this.$route.query.caseid;
            payload['case_check_id'] = this.nonRefCheckData.id;
            payload['case_name'] = query_id;
            payload['tool-name'] = 'gdc-tool';
            payload['status'] = 'RUNNING';
            payload['entity-id']= this.$route.query.entityId
            try {
                const url = `integrations/gdc/person/verify`
                let {data} = await axios.post(url, payload)
                // data.data.data.map(dta => {
                //     this.outputData.push(dta);
                // })
                if(!data.osint_status)
                    this.$toast.error(data.data.detail) 
                if(data?.data?.message && data?.data?.status == 1) this.$toast.success(`(${data?.data?.status_code})`+' '+data?.data?.message)
                else if(data?.data?.message && data?.data?.status == 0) this.$toast.error(`(${data?.data?.status_code})`+' '+data?.data?.message)
                this.outputData = data.data.data
                if(!this.isCurrentDivClicked) await this.getQueryData();
                this.$emit("updateStatus");
            }
            catch(error) {
                let message = "";
                const errorMessage = error?.response?.data?.detail[0]?.msg || "";
                const fieldName = error?.response?.data?.detail[0]?.loc?.[2] || "";
                if(errorMessage && fieldName) {
                    message = errorMessage.replace("this", fieldName);
                }
                this.$toast.error(message || this.$t('general.default_error'));
                this.selectTab('run_check');
            }
        },
        async getQueryData(){
            let query_id =  this.caseId;
            let entity_id = this.$route.query.entityId;
            const url = `/service/tools-status`;
           
            // "x-tool-name": "company-name","x-case-id": payload.case_id,"x-status":"RUNNING"
            try {
                const data = await blackboxAxios.post(url, 
                {
                    case_id: query_id,
                    entity_id: entity_id   
                }, {headers: {'x-case-id': this.caseId}})
                this.searchQueryData = data.data;
                const filteredQueryData = data?.data?.data?.filter(el => el.tool == 'gdc-tool').map((el) => ({
                    ...el,
                    ...{ 
                        query: {
                            ...el.query,
                            ... { request_id: el._id },
                        },
                    },
                })).sort((a, b) => {
                    const dateA = a.updated_at ? new Date(a.updated_at) : new Date(0); 
                    const dateB = b.updated_at ? new Date(b.updated_at) : new Date(0);
                    return dateB - dateA; // Desending order
                });
                this.queryDataFiltered = filteredQueryData
                this.$emit('gdcSearchQuery',filteredQueryData);

            }catch(error) {
                console.log(error);
            }
        },
        filteredGDCCheckData(data) {
            this.caseAndCheckData = data;
            this.isRunCheckSelected = true;
            this.isRunCheckLoaded = false;
            let address_countries = [];
            let currenCunIds = [];
            // let selectedOption = [];
            let selectedCountry = "";
            this.filteredData =  {
                'Name': [],
                'Address': [],
                'Email Id': {},
                'Date of Birth': {},
                'Phone Number': {},
                'ID': [],
            }
            data?.sections?.forEach(item => {
                this.isRunCheckLoaded = true;
                item?.entities?.forEach(blockData => {
                   
                    if (blockData?.form_entity_type == 'BLOCK') {
                        blockData?.entities?.forEach(val => {
                            if (val?.field_internal_name == 'phone_number'){
                                this.filteredData['Phone Number']['phone'] = val?.value;
                                this.filteredData['Phone Number'].checked = true
                            } 
                            else if (val?.field_internal_name == 'email_address') {
                                this.filteredData['Email Id']['email'] = val?.value;
                                this.filteredData['Email Id'].checked = true
                            }
                            else if (val?.field_internal_name == 'date_of_birth')  {
                                this.filteredData['Date of Birth']['dob'] = val?.value;
                                this.filteredData['Date of Birth'].checked = true
                            }
                            // else if (val?.field_internal_name)
                            if(val?.field_internal_name == 'gdc_country' && blockData?.block_name != 'Address Details') {
                                selectedCountry = val;
                                this.selectedCountryCode = val?.value
                                return;
                            }
                        })
                    } else if (blockData?.form_entity_type == 'FIELD') {
                        if (blockData?.field_internal_name == 'phone_number'){
                            this.filteredData['Phone Number']['phone'] = blockData?.value;
                            this.filteredData['Phone Number'].checked = false
                        }
                        else if (blockData?.field_internal_name == 'email_address') {
                            this.filteredData['Email Id']['email'] = blockData?.value;
                            this.filteredData['Email Id'].checked = false
                        }
                        else if (blockData?.field_internal_name == 'date_of_birth')  {
                            this.filteredData['Date of Birth']['dob'] = blockData?.value;
                            this.filteredData['Date of Birth'].checked = true
                        }
                        // else if (val?.field_internal_name)
                        if(blockData?.field_internal_name == 'gdc_country' && blockData?.block_name != 'Address Details') {
                            selectedCountry = blockData;
                            this.selectedCountryCode = blockData?.value
                            return;
                        }
                    }
                    if (selectedCountry && selectedCountry != '' && selectedCountry != undefined) {
                        
                        if (blockData?.form_entity_type == 'BLOCK') {
                            for (const el of blockData?.entities) {
                                let selectedElement =  el?.options?.find((el) => el?.["option_value"] === selectedCountry?.value);
                                if(selectedElement?.cascading_fields && selectedElement?.cascading_fields.length > 0 ){
                                    currenCunIds = selectedElement?.cascading_fields?.map((el) => el);
                                }
                            }
                        } else if (blockData?.form_entity_type == 'FIELD') {
                            for (const el of item?.entities) {
                                 let selectedElement =  el?.options?.filter((el) => el?.["option_value"] === selectedCountry?.value);
                                if(selectedElement?.cascading_fields && selectedElement?.cascading_fields.length > 0 ){
                                    currenCunIds = selectedElement?.cascading_fields?.map((el) => el);
                                }
                            }
                        }
                        // As Per Old Response
                        // if (blockData?.form_entity_type == 'BLOCK') {
                        //     currenCunIds = blockData?.entities?.filter(el => el?.condition?.dependence_value == selectedCountry?.value)
                        // } else if (blockData?.form_entity_type == 'FIELD') {
                        //     currenCunIds = item?.entities?.filter(el => el?.condition?.dependence_value == selectedCountry?.value)
                        // }
                        currenCunIds?.forEach(el => {
                            if (!this.filteredData?.['ID']?.some(obj => obj.idName == el?.label)) {
                                this.filteredData?.['ID']?.push({
                                    idName: el?.label,
                                    [el.label]: el?.value,
                                    currentCountry: selectedCountry?.options?.find(opel => opel.option_value == selectedCountry?.value)?.option_label,
                                    isVisible: true,
                                    checked: false, 
                                });
                            }
                        })
                    }
                    if(blockData?.block_name == "Name Details") {
                        let identity;
                        identity = {
                                first_name: blockData?.entities?.find(el => el?.field_internal_name == 'person_first_name')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'person_first_name')?.value,
                                middle_name: blockData?.entities?.find(el => el?.field_internal_name == 'person_middle_name')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'person_middle_name')?.value,
                                last_name: blockData?.entities?.find(el => el?.field_internal_name == 'person_last_name')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'person_last_name')?.value,
                                checked: false,
                        }
                        identity.full_name = identity?.first_name+' '+identity?.middle_name+' '+identity?.last_name 
                        identity.name = identity?.full_name.length > 35 ? identity?.full_name.substring(0,35)+'...' : identity?.full_name
                        this.filteredData['Name'].push(identity)
                    }
                    else if(blockData?.block_name == "Address Details") {
                        let countryData = blockData?.entities?.find(el => el?.field_internal_name == 'country')
                        address_countries.push(countryData?.options.find(el => el?.option_value == countryData?.value).option_label)
                        let address = {
                            addressLine1: blockData?.entities?.find(el => el?.field_internal_name == 'address_line_1')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'address_line_1')?.value ,
                            addressLine2: blockData?.entities?.find(el => el?.field_internal_name == 'address_line_2')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'address_line_2')?.value,
                            houseNumber: blockData?.entities?.find(el => el?.field_internal_name == 'house_number')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'house_number')?.value,
                            houseNumberAddition: blockData?.entities?.find(el => el?.field_internal_name == 'additional_house_number')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'additional_house_number')?.value,
                            thoroughfare: blockData?.entities?.find(el => el?.field_internal_name == 'street')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'street')?.value,
                            district: blockData?.entities?.find(el => el?.field_internal_name == 'neighbourhood/district')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'neighbourhood/district')?.value,
                            locality: blockData?.entities?.find(el => el?.field_internal_name == 'locality')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'locality')?.value,
                            postalCode: blockData?.entities?.find(el => el?.field_internal_name == 'postcode')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'postcode')?.value,
                            province: blockData?.entities?.find(el => el?.field_internal_name == 'county_/_provience_/_state')?.value == null ? '' : blockData?.entities?.find(el => el?.field_internal_name == 'county_/_provience_/_state')?.value,
                            country: countryData?.options.find(el => el?.option_value == countryData?.value).option_label,
                            countryCode: (countryData?.options.find(el => el?.option_value == countryData?.value).option_value).substring(0,2),
                            checked: false
                        }
                        address.title = address?.houseNumber+' '+address?.addressLine1+' '+address?.addressLine2+' '+address?.locality+' '+address?.postalCode
                        let fullAddress =  address?.houseNumber+' '+address?.addressLine1+' '+address?.addressLine2+' '+address?.locality+' '+address?.postalCode
                        fullAddress = fullAddress?.length > 40 ? fullAddress?.substring(0,40)+'...' : fullAddress
                        address.full_address = fullAddress
                        this.filteredData?.['Address']?.push(address)
                        if(address_countries?.length) {
                            address_countries = [...new Set(address_countries)]
                            this.countryList = address_countries;
                            // this.selectedCountry = address_countries?.[0];
                        }
                    }
                })
                this.isRunCheckLoaded = false;
                })
            this.filteredData['Address'] = this.filteredData?.Address?.filter( item => item?.country == this.selectedCountry);
            if(!this.filteredData?.Address?.length && !this.filteredData?.['Name']?.length && !this.filteredData?.['ID']?.length) {
                this.isFieldsReset = true;
            }
            else this.isFieldsReset = false;

            this.isRunCheckLoaded = false;
        },
        async getOutputScreen(item,id,cacheBypass) {
            this.isCurrentDivClicked = true;
            this.currentOutputId = id
            this.showOutputScreen = true;
            this.currentOutputData = item;
            let payload = item?.query
            try {
                this.activateLoader = true;
                // const token = VueCookies.get("saas-token")
                const token = VueCookies.get("token")
                item.query.request_id = item._id
                await this.verifyPersonIdentity(token, payload, item?.query, cacheBypass);
                this.activateLoader = false
                await this.selectTab('output');
            
            } catch(error) {
                this.activateLoader = false
                this.$toast.error(error?.response?.data?.detail[0]?.msg);
            }
        },
        async backToSearch(data){
            if(data) {
                this.selectedType = "";
                this.outputData = [];
                await this.selectTab("run_check");
            }
        },
        clearSearchQuery(){
            this.queryDataFiltered = [];
        },
        truncateAndAddDots(truncString, numberOfChar) {
            let truncatedString = '';
            if (truncString && truncString !== null && truncString !== 'null' && truncString?.length > numberOfChar) {
                truncatedString = truncString.substring(0, numberOfChar) + "..."
            } else {
                truncatedString = truncString;
            }
            return truncatedString;
        }
    },
};

</script>
<style scoped lang='scss'>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;

.parentBusinessCheckDiv{
    position: relative;
}

.runCheckCheckbox{
    border: 3px solid gray ;
    border-radius: 2px !important;
}

.rotateDropdownIcon{
    transform: rotate(180deg);
}

.runGdcButton {
    padding: 10px;
    margin: 20px 0px 10px 0px;
}
.runCheckLoader {
    width: 30px ;
    height: 30px;
}

.userDetails {
    width: 23rem !important;
    min-width: 23rem !important;
    border-radius: 7px !important;
}
.singleInfoFields{
    width: 23rem !important;
    min-width: 23rem !important;
    border-radius: 7px !important;
}

::v-deep {
    .gdc_country_dropdown .multiselect__tags {
        border-radius: 5px !important;
    }
    .gdc_country_dropdown .multiselect--active .icon {
    transform: rotate(180deg);
    }
    .multiselect .arrow{
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        right: 10px;
        top:27px;
    }
    .gdc_country_dropdown .multiselect__placeholder{
        padding-top: 0 !important;
        font-weight: 500 !important;
        color: black;
    }
    .userDetails .arrow{
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        left: 20px;
        top:15px;
    }
    .userDetails .idArrowVal,.idArrow {
        display: flex;
        flex-direction: row-reverse;
        position:relative;
        z-index: 1;
        right: 10px;
        top:15px;
    }
    .userDetails .multiselect__tags {
        border-radius: 10px !important;
        margin-top: -16px;
        position: relative;
        top:2px;
        border: none;
    }
    .userDetails .multiselect--active .icon {
    transform: rotate(180deg);
    }
    .idDropdown,.idValDropdown{
        width: 11rem !important;
        min-width: 11rem !important;
    }
    .fixed-height{
        height: 72vh !important;
    }
}
</style>