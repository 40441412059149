<!-- Localized -->
<template>
    <div class="profile-info transition-all overflow-y-auto scroll-bar bg-card-bg flex flex-col gap-6"
        :class="{
            'w-0 px-0 py-4': isCollapsed,
            'py-4 horizontal-padding': !isCollapsed,
        }">
        <!-- <profile-info-card @getCaseDetails="$emit('getCaseDetails', $event)" :isSystemIntegratedCheck="isSystemIntegratedCheck" :systemIntegratedCheckName="systemIntegratedCheckName" @getNeoCaseID="getNeoCaseID" v-if="entityType!='Case'"/> -->

        <div class="icons-section bg-gray-200 rounded-xl p-3">
            <icons-list :handleClearCallActiveLog="handleClearCallActiveLog" :icons="btns" :onSelect="handleShowModal" :activeIcon="activeIcon" :activity_count="activity_count">
                <template v-slot:item="{ item }">
                    <component :is="item.svg"></component>
                </template>
            </icons-list>
        </div>
        <div class="list-section overflow-y-auto scroll-bar pb-0.5" v-if="showCheckList">
            <ul class="static-list list-none bg-white p-0 mb-2">
                <li
                    class="lists lists__static flex justify-between items-center gap-6 max-h-10"
                    @click="$emit('isClicked', { key: 'overview', type: 'static' })"
                    :style="[
                        selectedCheck == 'overview'
                            ? {
                                  'background-color': '#cce3ff',
                                  'border-right': '4px solid #3b2cd4',
                              }
                            : {
                                  'background-color': '#f2f2f2',
                                  'border-right': '1px solid #a8a8a8',
                              },
                    ]"
                >
                    <OverviewIcon />

                    <span class="flex-1 py-1">{{ $t('components.overview') }}</span>
                </li>
            </ul>

            <!-- // actual check list from api  -->
            <ul class="list-none bg-white p-0" v-if="checkLists" v-allow:auth="'case.read_check_screen'">
                <li v-for="(list, idx) in checkLists"
                :key="list.check_id + list.check_name"
                class="flex justify-between cursor-pointer items-center px-4 py-2.5 gap-6 max-h-10"
                :class="[
                    isTimelineLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                    selectedCheck == list.check_id
                    ? 'border-primary-300  border-b bg-blue-100 border-r-4 hover:bg-blue-200  border '
                    : ' border hover:bg-base-200',
                    idx == checkLists.length - 1 ? '' : 'border-b-0',
                    CaseCheckFormPreviewLoader ? 'pointer-events-none text-gray-500' : ''
                ]"
                @click="$emit('isClicked', {
                    key: list.check_id,
                    type: list.check_category,
                    subtype: list.check_subtype,
                    stage_id: list.stage_id,
                    title: list.check_name,
                    id: list.id,
                    status: list.status,
                    statuses: list.statuses?.map((stat) => ({
                        ...stat,
                        selected: stat.status_id === list.status.status_id,
                    })),
                    integration_source_id: list?.integration_source_id,
                    description: list?.description,
                    check_label: list?.check_label,
                    check_name: list?.check_name,
                })">
                    <span class="flex-3 w-6 relative" :class="{'opacity-50': CaseCheckFormPreviewLoader}">
                        <EmploymentIcon v-if="list.svgName === 'employment'" />
                        <EducationIcon v-if="list.svgName === 'education'" />
                        <DifferenceIcon v-if="list.svgName === 'difference'" />
                        <OthersIcon v-if="list.svgName === 'other'" />
                        <OthersIcon v-else />

                    </span>
                    <span class="flex-1 text-left text-sm truncate" style="max-width: 270px">
                        {{ list?.check_label ||  list.check_name}}
                    </span>

                    <div class="flex items-center gap-1">
                        <DropdownMenu
                            :value="list.statuses"
                            value-attr="id"
                            :options="statusList"
                            trigger-class="card-short--check-status__dropdown px-2 py-0.5 rounded-md border"
                            :triggerStyles="{
                                'color': list?.status.status_colour,
                                'border': `1px solid ${list?.status.status_colour}`,
                            }"
                            :customStyles="{
                                maxWidth: '120px'
                            }"
                            :list-styles="{ maxWidth: '115px' }"
                            :disabled="selectedCheck !== list.check_id"
                            :onSelect="(e) => $emit('statusChanged', e.option, list.id)"
                        >
                            <template v-slot:selected>
                                <span class="rounded-full w-4 border border-solid border-gray-300 flex-shrink-0 h-4"
                                    :title="list?.status?.status_name"
                                    :style="{
                                        'background-color': list?.status?.status_colour
                                    }">
                                </span>
                                <!-- <div class="w-full text-center text-base truncate max-w-full text-xs font-bold" style="font-size:0.85rem" :title="list?.status?.status_name">
                                    {{ list?.status.status_name }}
                                </div> -->
                            </template>
                            <template v-slot:option="{ option }">
                                <span class="rounded-full w-4 border border-solid border-gray-300 flex-shrink-0 h-4" :style="{'background-color': option?.status_colour }"></span>
                                <div class="text-base truncate max-w-full font-bold" style="font-size:0.85rem" :title="option?.status_name" >
                                    {{ option?.status_name }}
                                </div>
                            </template>
                        </DropdownMenu>

                        <div class="px-2 py-0.5 rounded-full bg-brand flex text-center items-center cursor-default" 
                            v-if="list.eventCount && (list.check_name !== 'Search Engine' || getUserFeatures.includes('search_engine__monitoring'))" 
                            title="Monitoring Events">
                            <div class="font-semibold text-xs text-white mx-auto">{{ list.eventCount }}</div>
                        </div>

                        <i class="w-4 ml-1 mt-0.5 fa fa-angle-right"
                            :class="selectedCheck !== list.check_id ? 'text-gray-500': 'text-blue-600'"
                            @click.stop="expandQueries(list)"
                        ></i>
                    </div>

                    <!-- <span class="dots flex-1 gap-1 flex items-center justify-end" style="max-width: 25px">
                        <span
                        class="dots dots__dot1 transition-all  border border-solid border-gray-200"
                        v-for="ref in list.references"
                        :key="ref.case_reference_id"
                        :title="ref.status_name + '  ' + ref.status_description"
                        :style="{ backgroundColor: ref.status_color }"
                        ></span>
                    </span> -->
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
// import ProfileInfoCard from "../profile-info-card";
import EmploymentIcon from "@/assets/icons/svg/employment.svg";
import DifferenceIcon from "@/assets/icons/svg/difference.svg";
import EducationIcon from "@/assets/icons/svg/education.svg";
import OthersIcon from "@/assets/icons/svg/otherCheck.svg";
import OverviewIcon from "@/assets/icons/svg/overview.svg";
import Others from "@/assets/icons/svg/wOthers.svg";
import HistoryIcon from "@/assets/icons/svg/whistory.svg";
import FileManager from "@/assets/icons/svg/file-manager.svg";
import TaskIcon from "@/assets/icons/svg/wTask.svg";
import NotesIcon from "@/assets/icons/svg/wnotes.svg";
import TelIcon from "@/assets/icons/svg/wtel.svg";
import MailIcon from "@/assets/icons/svg/wEmail.svg";
import IconsList from "@/components/icons-list";
import DropdownMenu from '@shared/case-entities/components/DropdownMenu.vue'
const checklist = () => import("@/components/dashboard-components/checklist");
import { checklist as checklistData } from "@/components/dashboard-components/checklist/utils/checklist-data.js";
import { mapGetters } from 'vuex';

export default {
    name: "profile-info",
    components: {
        EducationIcon,
        "icons-list": IconsList,
        EmploymentIcon,
        "task-icon": TaskIcon,
        "tel-icon": TelIcon,
        "mail-icon": MailIcon,
        "notes-icon": NotesIcon,
        DifferenceIcon,
        OverviewIcon,
        "history-icon": HistoryIcon,
        others: Others,
        OthersIcon,
        DropdownMenu,
        // "profile-info-card": ProfileInfoCard,
        FileManager,
    },
    emits: ["isClicked"],
    props: {
        entityType: {
            type: String,
            default: "Case"
        },
        selectedCheck: {
            type: String,
            default: "",
        },
        handleClearCallActiveLog: Function,
        isCollapsed: {
            type: Boolean,
            default: false,
        },

        onSelectIconList: {
            type: Function,
            default: () => {},
        },
        activeIcon: {
            type: String,
            default: "",
        },
        checkLists: {
            type: Array,
            default: () => [],
        },
        activity_count: {
            type: Number,
            default: 0,
        },
        showCheckList: {
            type: Boolean,
            default: true
        },
        isSystemIntegratedCheck: {
            type: Boolean,
        },
        systemIntegratedCheckName: {
            type: Object,
            default: () => {},
        },
        isTimelineLoading: {
            type: Boolean,
            default: false,
        },
        CaseCheckFormPreviewLoader: {
            type: Boolean,
            default: false,
        },
        statusList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedConfig: {
                history: {
                    action: () => {
                        this.activeCheck = "";
                    },
                    component: checklist,
                    props: {
                        list: checklistData,
                    },
                },
            },
            lists: [],
            notes: {
                type: "",
                note: "",
            },
        };
    },
    mounted() {
        // this.id = this.$route.query["case-id"];
    },
    methods: {
        hanldeCloseModal(modal_name) {
            this.$modal.hide(modal_name);
        },

        handleShowModal(icon) {
            this.$modal.show(icon.key);
            this.onSelectIconList(icon.key);
        },
        method(modal_name) {
            if (modal_name == "add-note") {
                this.hanldeCloseModal(modal_name);
            }
            if (modal_name == "send-mail") {
                this.sendOptions = !this.sendOptions;
            }
            if (modal_name == "show-roles") {
                this.roleOptions = !this.roleOptions;
            }
        },
        getNeoCaseID(val){
            this.$emit("getNeoCaseID", val);
        },
        expandQueries(list) {
            if (this.selectedCheck !== list.check_id) return;
            this.$emit('expandQueries');
        },
    },
    computed: {
        ...mapGetters(["getUserFeatures"]),
        btns() {
            return [
                {
                    svg: "mail-icon",
                    key: "mail",
                    title: "Email",
                    external: true
                },
                {
                    svg: "file-manager",
                    key: "file-manager",
                    title: "File Manager",
                    external: false
                },
                {
                    svg: "tel-icon",
                    key: "tel",
                    title: "Call",
                    external: true
                },
                {
                    svg: "notes-icon",
                    key: "notes",
                    title: "Note",
                    external: false
                },
                {
                    svg: "task-icon",
                    key: "task",
                    title: "Task",
                    external: false
                },
                {
                    svg: "history-icon",
                    key: "history",
                    title: "History",
                    count: true,
                    external: false
                },
                // {
                //   svg: "others",
                //   key: "others",
                // },
            ]
        },
    },
};
</script>

<style lang="scss" scoped>
.list-section {
    .static-list {
        :nth-child(1) {
            border-top: 1px solid #a8a8a8;
        }
    }
}
.gray-bg {
    background-color: #f2f2f2;
}

.profile-info {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: fit-content;
    max-width: 400px;

    &.horizontal-padding {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}
.lists {
    cursor: pointer;
    padding: 12px 0px 12px 20px;
    border: 1px solid #a8a8a8;
    text-align: left;
    vertical-align: middle;
    transition: background-color 300ms, border-right 100ms;
    // &:hover {
    //   background-color: #cce3ff;
    //   border-right: 4px solid #3b2cd4;
    // }
    &:not(:last-child) {
        border-bottom-width: 0;
    }
    &__static {
        background-color: #f2f2f2;
    }
}
.dots {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    &__dot1 {
        background-color: #0dfb50;
    }
    &__dot2 {
        background-color: #ff0000;
    }
    &__dot3 {
        background-color: #a3a4a3;
    }
    &__dot4 {
        background-color: #5ed1f6;
    }
}
</style>
