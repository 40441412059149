var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-info transition-all overflow-y-auto scroll-bar bg-card-bg flex flex-col gap-6",class:{
        'w-0 px-0 py-4': _vm.isCollapsed,
        'py-4 horizontal-padding': !_vm.isCollapsed,
    }},[_c('div',{staticClass:"icons-section bg-gray-200 rounded-xl p-3"},[_c('icons-list',{attrs:{"handleClearCallActiveLog":_vm.handleClearCallActiveLog,"icons":_vm.btns,"onSelect":_vm.handleShowModal,"activeIcon":_vm.activeIcon,"activity_count":_vm.activity_count},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(item.svg,{tag:"component"})]}}])})],1),(_vm.showCheckList)?_c('div',{staticClass:"list-section overflow-y-auto scroll-bar pb-0.5"},[_c('ul',{staticClass:"static-list list-none bg-white p-0 mb-2"},[_c('li',{staticClass:"lists lists__static flex justify-between items-center gap-6 max-h-10",style:([
                    _vm.selectedCheck == 'overview'
                        ? {
                              'background-color': '#cce3ff',
                              'border-right': '4px solid #3b2cd4',
                          }
                        : {
                              'background-color': '#f2f2f2',
                              'border-right': '1px solid #a8a8a8',
                          },
                ]),on:{"click":function($event){return _vm.$emit('isClicked', { key: 'overview', type: 'static' })}}},[_c('OverviewIcon'),_c('span',{staticClass:"flex-1 py-1"},[_vm._v(_vm._s(_vm.$t('components.overview')))])],1)]),(_vm.checkLists)?_c('ul',{directives:[{name:"allow",rawName:"v-allow:auth",value:('case.read_check_screen'),expression:"'case.read_check_screen'",arg:"auth"}],staticClass:"list-none bg-white p-0"},_vm._l((_vm.checkLists),function(list,idx){return _c('li',{key:list.check_id + list.check_name,staticClass:"flex justify-between cursor-pointer items-center px-4 py-2.5 gap-6 max-h-10",class:[
                _vm.isTimelineLoading ? 'cursor-not-allowed' : 'cursor-pointer',
                _vm.selectedCheck == list.check_id
                ? 'border-primary-300  border-b bg-blue-100 border-r-4 hover:bg-blue-200  border '
                : ' border hover:bg-base-200',
                idx == _vm.checkLists.length - 1 ? '' : 'border-b-0',
                _vm.CaseCheckFormPreviewLoader ? 'pointer-events-none text-gray-500' : ''
            ],on:{"click":function($event){_vm.$emit('isClicked', {
                key: list.check_id,
                type: list.check_category,
                subtype: list.check_subtype,
                stage_id: list.stage_id,
                title: list.check_name,
                id: list.id,
                status: list.status,
                statuses: list.statuses?.map((stat) => ({
                    ...stat,
                    selected: stat.status_id === list.status.status_id,
                })),
                integration_source_id: list?.integration_source_id,
                description: list?.description,
                check_label: list?.check_label,
                check_name: list?.check_name,
            })}}},[_c('span',{staticClass:"flex-3 w-6 relative",class:{'opacity-50': _vm.CaseCheckFormPreviewLoader}},[(list.svgName === 'employment')?_c('EmploymentIcon'):_vm._e(),(list.svgName === 'education')?_c('EducationIcon'):_vm._e(),(list.svgName === 'difference')?_c('DifferenceIcon'):_vm._e(),(list.svgName === 'other')?_c('OthersIcon'):_c('OthersIcon')],1),_c('span',{staticClass:"flex-1 text-left text-sm truncate",staticStyle:{"max-width":"270px"}},[_vm._v(" "+_vm._s(list?.check_label || list.check_name)+" ")]),_c('div',{staticClass:"flex items-center gap-1"},[_c('DropdownMenu',{attrs:{"value":list.statuses,"value-attr":"id","options":_vm.statusList,"trigger-class":"card-short--check-status__dropdown px-2 py-0.5 rounded-md border","triggerStyles":{
                            'color': list?.status.status_colour,
                            'border': `1px solid ${list?.status.status_colour}`,
                        },"customStyles":{
                            maxWidth: '120px'
                        },"list-styles":{ maxWidth: '115px' },"disabled":_vm.selectedCheck !== list.check_id,"onSelect":(e) => _vm.$emit('statusChanged', e.option, list.id)},scopedSlots:_vm._u([{key:"selected",fn:function(){return [_c('span',{staticClass:"rounded-full w-4 border border-solid border-gray-300 flex-shrink-0 h-4",style:({
                                    'background-color': list?.status?.status_colour
                                }),attrs:{"title":list?.status?.status_name}})]},proxy:true},{key:"option",fn:function({ option }){return [_c('span',{staticClass:"rounded-full w-4 border border-solid border-gray-300 flex-shrink-0 h-4",style:({'background-color': option?.status_colour })}),_c('div',{staticClass:"text-base truncate max-w-full font-bold",staticStyle:{"font-size":"0.85rem"},attrs:{"title":option?.status_name}},[_vm._v(" "+_vm._s(option?.status_name)+" ")])]}}],null,true)}),(list.eventCount && (list.check_name !== 'Search Engine' || _vm.getUserFeatures.includes('search_engine__monitoring')))?_c('div',{staticClass:"px-2 py-0.5 rounded-full bg-brand flex text-center items-center cursor-default",attrs:{"title":"Monitoring Events"}},[_c('div',{staticClass:"font-semibold text-xs text-white mx-auto"},[_vm._v(_vm._s(list.eventCount))])]):_vm._e(),_c('i',{staticClass:"w-4 ml-1 mt-0.5 fa fa-angle-right",class:_vm.selectedCheck !== list.check_id ? 'text-gray-500': 'text-blue-600',on:{"click":function($event){$event.stopPropagation();return _vm.expandQueries(list)}}})],1)])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }